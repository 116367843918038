import React from "react";
import PropTypes from "prop-types";
import SectionTitle from "@ui/section-title";
import SwiperSlider, { SwiperSlide } from "@ui/swiper";
import Testimonial from "@components/testimonial/layout-02";
import { SectionTitleType, ItemType } from "@utils/types";
import { TestimonialWrappper } from "./style";
import { Container } from "react-bootstrap";

const slider = {
  slidesPerView: 3,
  centeredSlides: true,
  loop: true,
  speed: 1000,
  spaceBetween: 0,
  pagination: true,
  breakpoints: {
    320: {
      slidesPerView: 1,
    },
    991: {
      slidesPerView: 2,
    },
    1499: {
      slidesPerView: 3,
    },
  },
};

const TestimonialArea = React.memo(({ data,layout }) => {
  return (
    <TestimonialWrappper layout={layout}>
      <Container>
        {data?.section_title && (
          <SectionTitle
            mb={["45px", null, "60px"]}
            title={data.section_title?.title}
            subtitle={data.section_title?.subtitle}
          />
        )}
        {data?.items?.length && (
          <SwiperSlider options={slider} paginationTop="80px" opacityStyle>
            {data.items?.map((testimonial , index) => (
              <SwiperSlide className="item" key={index}>
                <Testimonial
                  authorImg={testimonial.images?.[0]}
                  review={testimonial.description}
                  subject={testimonial.subject}
                />
              </SwiperSlide>
            ))}
          </SwiperSlider>
        )}
      </Container>
    </TestimonialWrappper>
  );
});

TestimonialArea.propTypes = {
  data: PropTypes.shape({
    section_title: PropTypes.shape(SectionTitleType),
    items: PropTypes.arrayOf(PropTypes.shape(ItemType)),
  }),
};

export default TestimonialArea;
